import * as THREE from 'three';

import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
//import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {UnrealBloomPass} from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass.js';
//import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
//import {FXAAShader} from 'three/examples/jsm/shaders/FXAAShader.js';
//import {SMAAPass} from 'three/examples/jsm/postprocessing/SMAAPass.js';
// import WebGL from 'three/examples/jsm/capabilities/WebGL.js';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
// import {GUI} from 'dat.gui';

const headGLTFImported = new URL('../Assets/head_gltfpack_cctc.glb', import.meta.url)
const hairGLTFImported = new URL('../Assets/hair_gltfpack_cctc.glb', import.meta.url)

const hdri = new URL("../Assets/hdri_1_edited.hdr", import.meta.url)

let headContainer = document.querySelector("#head-container")
let projectPurpleBackground = document.querySelector("#project-purple-background")
headContainer.style.overflow = "default";
let currentTime, percentLoaded, frustumSizeMax, m, b, GLTFHead, mousecoords, mousecoords2, isTouchDevice, resizeTimer, stopRender, isScrolling, mixer, GLTFHair, headTarget, chin, neck, head, dpiDivide, hConstant, wConstant;

hConstant = window.innerHeight;
wConstant = window.innerWidth;

let chinStartTime = 1.8;
let chinEndTime = 4; // set time for initial head movement

let initialExposure = 0.2;
let finalExposure = 0.95;
let loadDimStartTime = 1;
let loadDimEndTime = 1.5; //change these to change light up time for initial load

const frustumSizeMin = 29;
const aspectRatio1 = 1.7;
const aspectRatio2 = 0.5;
let frustumSize = 29; // reduce this to increase zoom
let frustumHeight = 3.8; // increase this to bring lower

if(window.innerWidth < 600) {
    frustumSizeMax = 45;
} else {
    frustumSizeMax = 50;
}

setTimeout(()=> {
    document.querySelector("#central-div-2").style.opacity = 1;
}, 2000 )

m = (frustumSizeMax - frustumSizeMin) / (aspectRatio2 - aspectRatio1);
b = frustumSizeMin - m * aspectRatio1;

const speed = 0.25; // speed of movement of head, set at 0.25

const inactivityThreshold = 5000; // time before head moves back to position

let scene = new  THREE.Scene();
let w = window.innerWidth;
let h = window.innerHeight;
let aspectRatio = w/h;
let nearPlane = 1;
let farPlane = 400;

if(aspectRatio < 1.7) { // reduces height of scene beyond a certian aspect ratio
    frustumSize =  m * aspectRatio + b;
} else {
    frustumSize = 29;
}

let camera = new  THREE.OrthographicCamera(
    frustumSize*aspectRatio/-2, frustumSize*aspectRatio/2, frustumSize+frustumHeight, frustumHeight, nearPlane, farPlane
)

camera.position.set( 0, 0, 200);
camera.updateProjectionMatrix();
// camera.updateMatrix();

const theCanvas = document.querySelector("#artboard");

let renderer = new THREE.WebGLRenderer({
    canvas : artboard,
    alpha : true,
    powerPreference: "high-performance"
    // antialias : true
//    preserveDrawingBuffer: true 
})

// // set below for changing exposure
renderer.toneMapping = true;
THREE.ColorManagement.enabled = false;

renderer.outputColorSpace = THREE.LinearSRGBColorSpace;
renderer.toneMapping = THREE.LinearToneMapping; 

renderer.setClearColor( 0x000000, 0 ); // the default

if(window.innerWidth < 600) {
    dpiDivide = 2;
} else if (window.devicePixelRatio >= 2 && window.innerWidth >= 600) {
    dpiDivide = 1.5;
} else {
    dpiDivide = 1;
}

console.log(dpiDivide)

renderer.setSize(w , h);
renderer.setPixelRatio(dpiDivide);

theCanvas.style.overflow = "hidden";

theCanvas.style.width = `${w}px`;
theCanvas.style.height = `${h}px`;

renderer.shadowMap.enabled = true;
// renderer.shadowMap.type =  THREE.PCFSoftShadowMap;
document.querySelector("#head-container").appendChild(renderer.domElement);

renderer.physicallyCorrectLights = true;

const hemLight = new  THREE.HemisphereLight( 0xabd5f7, 0x080820, 70 );
// hemLight.castShadow = true;
scene.add( hemLight );

const leftSpotLight = new  THREE.SpotLight( 0x83aef2, 200, 100, undefined, undefined, 1 ); // light on top left, cutting in hair
leftSpotLight.position.set( -16, 20, 5 );
leftSpotLight.target.position.set(-15, 45, 7)
// leftSpotLight.castShadow = true;
leftSpotLight.target.updateMatrixWorld();
scene.add( leftSpotLight );

const rightSpotLight = new  THREE.SpotLight( 0x83aef2, 40, 20, undefined, undefined, 0.4  ); // light falling on sweater on the right
rightSpotLight.position.set( 15, 15, 5 );
rightSpotLight.target.position.set(15, 0, -6)
//rightSpotLight.castShadow = true;
rightSpotLight.target.updateMatrixWorld();
scene.add( rightSpotLight );

const pointLight2 = new  THREE.PointLight( 0xA3FFFF, 200, 32, 1 ); // light at the back, towards the top
pointLight2.position.set( 0, 30, -25 );
// pointLight2.castShadow = true;
scene.add( pointLight2 );

const pointLight3 = new  THREE.PointLight( 0xA3FFFF, 4000, 29, 1 ); // blue white light on left, towards the back (falling on sweater on left) (causing bloom) (original 4000)
pointLight3.position.set( -20, 24, -21 );
// pointLight3.castShadow = true;
scene.add( pointLight3 );

const pointLight4 = new  THREE.PointLight( 0xA3FFFF, 6000, 30, 1 ); // whitish light on right side hair
pointLight4.position.set( 20, 21, -25 );
// pointLight4.castShadow = true;
scene.add( pointLight4 );

const spotLight = new  THREE.SpotLight( 0xA3FFFF, 300, 31, undefined, undefined, 1  ); // light at the back, shining white light on hair from bottom
spotLight.position.set( 0, 3, -25 );
spotLight.target.position.set(0,0,0)
//spotLight.castShadow=true;
scene.add( spotLight );

const eyeLightLeft = new  THREE.PointLight( 0x0d6aff, 20, 24, 1 );
eyeLightLeft.position.set( 2.1, 19.7, 18 );
scene.add( eyeLightLeft );

const pmremGenerator = new  THREE.PMREMGenerator( renderer );
pmremGenerator.compileEquirectangularShader();

const hdriLoader = new RGBELoader();

function loadHDRI() {
    hdriLoader.load( hdri, function ( texture ) {
        const envMap = pmremGenerator.fromEquirectangular( texture ).texture;
        
        texture.dispose(); 
            
        scene.environment = envMap
        // scene.background = envMap
    } );
}

loadHDRI();

function loadGLTFHead(GLTFName) {
    let loader = new GLTFLoader();
    loader.setMeshoptDecoder( MeshoptDecoder );

    loader.load(GLTFName, function(gltf) {
        GLTFHead = gltf.scene;

        document.querySelector("#loader").classList.add("loader-fade-out");

        if (typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function' && isTouchDevice) {
            document.querySelector("#start-button-container").classList.add("start-button-fade-in");
        }

        setTimeout(()=> {
            document.querySelector("#loader").style.display = "none";
        }, 1000 )

        currentTime = 0;

        manipulateModel(GLTFHead);

        GLTFHead.traverse((child) => {
            if (child.isMesh) {
              if (child.name === 'mesh_0') { // mesh_0 is the name of sweater mesh after compression
                // Access the material of the sweater mesh and adjust its properties
                const sweaterMaterial = child.material;
                sweaterMaterial.roughness = 0.77; // The more, the darker
                sweaterMaterial.color = new THREE.Color(0x07070a)
                sweaterMaterial.metalness = 1;
              }
            //   if (child.name === 'Eyes') { // Replace 'sweater_mesh_name' with the actual name
            //     // Access the material of the eye mesh and adjust its properties
            //     eyeMaterial = child.material;

            //     eyeMaterial.color = new THREE.Color(0x07070a)
            //     eyeMaterial.emissiveIntensity = eyeFinalEmissiveIntensity;
            //   } 
            //   if(child.name === "Face_3D") {
            //     child.material.emissiveIntensity = 0;
            //   }
            }
        });

        initiateLoadingDivs();

    }, function(xhr) {
        percentLoaded = (xhr.loaded / xhr.total) * 100;
        percentLoaded = percentLoaded > 100 ? 100 : percentLoaded;
        document.getElementById('loading-bar').style.width = percentLoaded + '%';
        document.getElementById('loading-text').textContent = Math.floor(percentLoaded) + '%';
    }, function(error) {
        console.log(error)
    })
}

loadGLTFHead(headGLTFImported.href)

function initiateLoadingDivs() {
    setTimeout(() => {
        document.querySelector("#central-div-1 > .central-left-div").classList.add("central-left-div-load-animation");
    }, 3200)
    setTimeout(() => {
        document.querySelector("#central-div-1 > .central-right-div").classList.add("central-right-div-load-animation");
    }, 3450)
    setTimeout(() => {
        document.querySelector("#bg-banner-1").classList.add("bg-banner-load-animation");
    }, 2300)
    setTimeout(() => {
        document.querySelector("#bottom-nav").classList.add("nav-load-animation");
        document.querySelector("#top-nav").classList.add("nav-load-animation");
    }, 4000)
    setTimeout(() => {
        document.querySelector("#backgrounds").classList.add("background-load-animation");
    }, 1250)
}

// canvas opacity in the beginnig
theCanvas.style.opacity = 0;

function updateCanvasOpacity() {
    // Check if currentTime is within the desired range (0.1 to 0.3)
    if (GLTFHead && currentTime >= 0.2 && currentTime <= 0.4) {
        // Calculate the opacity based on the range of currentTime
        const opacity = (currentTime - 0.2) / (0.4 - 0.2); // Mapping currentTime to opacity range (0 to 1)
    
        // Set the opacity of theCanvas div
        theCanvas.style.opacity = opacity;
    }
}

let rotationOrigin = new  THREE.Vector3();

let hairArray = [];

function manipulateModel(model) {

    model.traverse((child) => {
        if ( child.type == 'SkinnedMesh' ) {
          child.frustumCulled = false;
        //   child.geometry.computeTangents()
        }
        if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
        }
        if (child.isMesh && child.name === 'Hair') {
            hairMesh = child;
        }
        // if (child.isMesh && child.name === 'Eyes') { // for changing eye material
        //     child.material = eyeMaterial
        // }
    });

    model.traverse(o => {
        if (o.isBone && o.name.includes("Hair") && o.name.length<7) {
          hairArray.push(o)
        }
        if (o.isBone && o.name === 'head_target') { 
            headTarget = o;
        }
        if (o.isBone && o.name === 'Chin') { 
            chin = o;
            rotationOrigin.copy(chin.position);
        }
        if (o.isBone && o.name === 'Neck') { 
            neck = o;
        }
        if (o.isBone && o.name === 'Head') { 
            head = o;
        }
    });

    scene.add(model);

    chin.rotation.x = Math.PI/2+ degToRad(12.75); // setting initial position of chin
    
}

isTouchDevice = false; // Flag to track if the device is a touch device

if (('ontouchstart' in window || navigator.maxTouchPoints) && window.innerWidth < 600) {
    isTouchDevice = true;
} else {
    isTouchDevice = false;
}

let lastMouseMoveTime = Date.now();

window.addEventListener('mousemove', function(e) {
    lastMouseMoveTime = Date.now();

    if(currentTime > chinEndTime) { //change the time here according to when other animations are complete

        mousecoords = getMousePos(e);

        if (chin && !isTouchDevice) {
            moveJoint(mousecoords, chin, 25);
        }
    }

    mousecoords2 = getMousePos(e);

    if (chin && currentTime > chinEndTime  && !isTouchDevice) { // change the time here to reflect when to move head around
        moveJoint2(mousecoords2, GLTFHair, 25);
    }
});

if (typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function') {
    document.querySelector("#start-button-container").addEventListener('touchend', ()=> {
        DeviceOrientationEvent.requestPermission().then(permissionState => {
            if (permissionState === 'granted') {
                window.addEventListener('deviceorientation', (event) => {
                    lastMouseMoveTime = Date.now();
                    if(currentTime > chinEndTime) { //change the time here according to when other animations are complete
                
                        mousecoords = getTouchPos(event);
                
                        if (chin && isTouchDevice) {
                            moveJoint(mousecoords, chin, 15);
                        }
                    }
                
                    mousecoords2 = getTouchPos(event);
                
                    if (chin && currentTime > chinEndTime && isTouchDevice) { // change the time here to reflect when to move head around
                        moveJoint2(mousecoords2, GLTFHair, 15);
                    }
                });
            }

            document.querySelector("#start-button-container").classList.remove("start-button-fade-in");

            setTimeout(()=> {
                document.querySelector("#start-button-container").style.display = "none";
            }, 1000 )
        })
        .catch(console.error)
    })
} else {
    // handle regular non iOS 13+ devices
    window.addEventListener('deviceorientation', function(event) {
        lastMouseMoveTime = Date.now();
        if(currentTime > chinEndTime) { //change the time here according to when other animations are complete

            mousecoords = getTouchPos(event);

            if (chin && isTouchDevice) {
                moveJoint(mousecoords, chin, 25);
            }
        }

        mousecoords2 = getTouchPos(event);

        if (chin && currentTime > chinEndTime && isTouchDevice) { // change the time here to reflect when to move head around
            moveJoint2(mousecoords2, GLTFHair, 25);
        }
    });
}

function checkForInactivityChin(joint) {
    const currentTime2 = Date.now();
    const timeSinceLastMove = currentTime2 - lastMouseMoveTime;

    if (timeSinceLastMove >= inactivityThreshold && joint) {
        const targetRotationY = 0;
        const targetRotationZ = 0;
        const targetRotationX = Math.PI / 2;
    
        // Interpolate the current rotation towards the target rotation gradually
        joint.rotation.y += (targetRotationY - joint.rotation.y) * speed * 0.15;
        joint.rotation.z += (targetRotationZ - joint.rotation.z) * speed * 0.15;
        joint.rotation.x += (targetRotationX - joint.rotation.x) * speed * 0.15;
    }
}

function checkForInactivityHair(joint) {
    const currentTime3 = Date.now();
    const timeSinceLastMove = currentTime3 - lastMouseMoveTime;

    if (timeSinceLastMove >= inactivityThreshold && joint) {
        const targetRotationY = 0;
        const targetRotationZ = 0;
        const targetRotationX = 0;
    
        // Interpolate the current rotation towards the target rotation gradually
        joint.rotation.y += (targetRotationY - joint.rotation.y) * speed * 0.15;
        joint.rotation.z += (targetRotationZ - joint.rotation.z) * speed * 0.15;
        joint.rotation.x += (targetRotationX - joint.rotation.x) * speed * 0.15;
    }
}

function getMousePos(e) { // for desktop
    return { x: e.clientX, y: e.clientY };
}



// function initWebGLObjects(existingScene) {
//     console.log("Initializing WebGL objects");
  
//     const scene = existingScene;
  
//     const geometry = new THREE.BufferGeometry();
//     const vertices = [];
//     const parameters = [
//       [[1, 1, 1], 1],
//       [[1, 1, 1], 1]
//     ];
  
//     for (let i = 0; i < 350; i++) {
//       const x = Math.random() * 60 - 30;
//       const y = Math.random() * 60 - 30;
//       const z = Math.random() * 60 - 30;
//       vertices.push(x, y, z);
//     }
  
//     geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
  
//     for (let i = 0; i < parameters.length; i++) {
//       const color = parameters[i][0];
//       const size = parameters[i][1];
  
//       const material = new THREE.PointsMaterial({
//         size: size,
//         color: new THREE.Color(color[0], color[1], color[2]),
//         blending: THREE.AdditiveBlending,
//         depthTest: false,
//         transparent: true,
//         opacity: 0.9,
//         emissive: new THREE.Color(color[0], color[1], color[2]),
//         emissiveIntensity: 1
//       });
  
//       const particles = new THREE.Points(geometry.clone(), material); // Clone the geometry for each particle

//       particles.position.set(
//         Math.random() * 60 - 30,
//         Math.random() * 60 - 30,
//         Math.random() * 60 - 30
//       );
  
//       particles.rotation.set(
//         Math.random() * 6,
//         Math.random() * 6,
//         Math.random() * 6
//       );
  
//       scene.add(particles);
//     }
// }
  
// initWebGLObjects(scene);

// touch movement ______________________________________________________________________________________________________________________________

function getTouchPos(e) { // For touch-screen mobile devices
    if (window.DeviceOrientationEvent) {
        let tiltX;
        if(e.beta < 95) {
            tiltX = (window.innerWidth/2) - Math.round(e.gamma * 5)
        }
        const tiltY = (window.innerHeight/2) - Math.round((e.beta-30) * 8);

        return { x: tiltX, y: tiltY };
    } else {
        return { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY };
    }
}

// touch movement ______________________________________________________________________________________________________________________________


function moveJoint(mouse, joint, degreeLimit) {
    let degrees = getMouseDegrees(mouse.x, mouse.y, degreeLimit);

    const targetRotationY = - degToRad(degrees.x) / 2.5;
    const targetRotationZ = - degToRad(degrees.x);
    const targetRotationX = Math.PI / 2 +  degToRad(degrees.y);

    // Interpolate the current rotation towards the target rotation gradually
    joint.rotation.y += (targetRotationY - joint.rotation.y) * speed;
    joint.rotation.z += (targetRotationZ - joint.rotation.z) * speed;
    joint.rotation.x += (targetRotationX - joint.rotation.x) * speed;
}

function getMouseDegrees(x, y, degreeLimit) {
    let dx = 0,
        dy = 0,
        xdiff,
        xPercentage,
        ydiff,
        yPercentage;
  
    let w = { x: window.innerWidth, y: window.innerHeight };
  
    // Left (Rotates neck left between 0 and -degreeLimit)
    
     // 1. If cursor is in the left half of screen
    if (x <= w.x / 2) {
      // 2. Get the difference between middle of screen and cursor position
      xdiff = w.x / 2 - x;  
      // 3. Find the percentage of that difference (percentage toward edge of screen)
      xPercentage = (xdiff / (w.x / 2)) * 100;
      // 4. Convert that to a percentage of the maximum rotation we allow for the neck
      dx = ((degreeLimit * xPercentage) / 100) * -1; }
  // Right (Rotates neck right between 0 and degreeLimit)
    if (x >= w.x / 2) {
      xdiff = x - w.x / 2;
      xPercentage = (xdiff / (w.x / 2)) * 100;
      dx = (degreeLimit * xPercentage) / 100;
    }
    // Up (Rotates neck up between 0 and -degreeLimit)
    if (y <= w.y / 2) {
      ydiff = w.y / 2 - y;
      yPercentage = (ydiff / (w.y / 2)) * 100;
      // Note that I cut degreeLimit in half when she looks up
      dy = (((degreeLimit * 0.5) * yPercentage) / 100) * -1;
      }
    
    // Down (Rotates neck down between 0 and degreeLimit)
    if (y >= w.y / 2) {
      ydiff = y - w.y / 2;
      yPercentage = (ydiff / (w.y / 2)) * 100;
      dy = (degreeLimit * yPercentage) / 100;
    }
    return { x: dx, y: dy };
}

// ___________________________________________________
function loadGLTFHair(GLTFName) {
    let loader = new  GLTFLoader();
    loader.setMeshoptDecoder( MeshoptDecoder );

    loader.load(GLTFName, function(gltf) {
        GLTFHair = gltf.scene;
        let GLTFAnimationsHair = gltf.animations;
        manipulateModel2(GLTFHair, GLTFAnimationsHair)
    }, undefined, function(error) {
        console.log(error)
    })
}

function manipulateModel2(model, animations) {
    mixer = new THREE.AnimationMixer(model);
    const clips = animations;
    const clip =  THREE.AnimationClip.findByName(clips, 'hair_flow_anim')
    const action = mixer.clipAction(clip);
    action.play();

    model.traverse((child) => {
        if ( child.type == 'SkinnedMesh' ) {
          child.frustumCulled = false;
        //   child.geometry.computeTangents()
        }
        if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
        }
    });
    model.position.y = 13.7;
    model.position.z = 2.5;

    scene.add(model);

    model.rotation.x =  degToRad(12.75); // setting initial position of hair
}

// Define the ease-out function
function easeInOutCubic(t) {
    return 1 - Math.pow(1 - t, 4); // Using (1 - t)^4 instead of (3t^2 - 2t^3)
}

function animateChin() {

    if(currentTime > chinStartTime && currentTime < chinEndTime) {
        // chin.rotation.x = (- degToRad(12.75))/(chinEndTime-chinStartTime) * (currentTime-chinStartTime) + Math.PI/2+ degToRad(12.75) //y=mx+c
        const tNormalized = (currentTime - chinStartTime) / (chinEndTime - chinStartTime);
        const easedT = easeInOutCubic(tNormalized);

        const startRotationChin = Math.PI / 2 +  degToRad(12.75);
        const startRotationHair =  degToRad(12.75);
        const endRotation = Math.PI / 2; // Assuming final value is Math.PI/2

        chin.rotation.x = (- degToRad(12.75)) * easedT + startRotationChin;
        GLTFHair.rotation.x = (- degToRad(12.75)) * easedT + startRotationHair;
    }
}


function moveJoint2(mouse, joint, degreeLimit) {
    let degrees = getMouseDegrees(mouse.x, mouse.y, degreeLimit);

    // joint.rotation.z = - degToRad(degrees.x)/7;
    // joint.rotation.y =  degToRad(degrees.x);
    // joint.rotation.x = 2*Math.PI+ degToRad(degrees.y); // these work for chin

    const targetRotationZ = - degToRad(degrees.x) / 7;
    const targetRotationY =  degToRad(degrees.x);
    const targetRotationX =  degToRad(degrees.y);

    joint.rotation.z += (targetRotationZ - joint.rotation.z) * speed;
    joint.rotation.y += (targetRotationY - joint.rotation.y) * speed;
    joint.rotation.x += (targetRotationX - joint.rotation.x) * speed;

}

loadGLTFHair(hairGLTFImported.href)

// theCanvas.style.position = 'fixed';

const clock = new  THREE.Clock();

// Function to change toneMappingExposure at specific times
renderer.toneMappingExposure = initialExposure;

function changeExposureOverTime(startTime, endTime) {
    if(currentTime > startTime && currentTime < endTime) {
        renderer.toneMappingExposure = ((finalExposure-initialExposure)/(endTime-startTime)*(currentTime-startTime)) + initialExposure
    } else if (currentTime <= startTime) {
        renderer.toneMappingExposure = initialExposure;
    }
}

// // camera movement for skills div
isScrolling = false;

function updateCamera() {
    // Calculate the scroll progress within the range
    let distanceFromTop = window.innerHeight - document.querySelector("#skills-div").getBoundingClientRect().top;

    let scrollProgress = 1 - (distanceFromTop / (window.innerHeight - window.innerHeight/4));
    
    // Ensure scrollProgress stays within 0 and 1
    scrollProgress = Math.min(1, Math.max(0, scrollProgress));
    
    // Adjust scrollProgress to increase linearly from 0 to 1
    scrollProgress = 1 - scrollProgress;    // Ensure scroll is within the specified range

    if (document.querySelector("#skills-div").getBoundingClientRect().top < window.innerHeight) { // during camera movement
        // Calculate the new frustum size for zooming out
        let newFrustumSize = 29 + scrollProgress * 3; // Adjust the zoom level as needed

        if(aspectRatio < 1.7) newFrustumSize = (m * aspectRatio + b) + scrollProgress * 3;

        camera.left = newFrustumSize * aspectRatio / -2 + scrollProgress * 65;
        camera.right = newFrustumSize * aspectRatio / 2 + scrollProgress * 65;
        camera.top = newFrustumSize + frustumHeight - scrollProgress * 22;
        camera.bottom = frustumHeight  - scrollProgress * 22;
        
        // Update the camera's look-at direction (assuming 'object' is the object you want to focus on)
        camera.lookAt(-scrollProgress * 90 , scrollProgress * 25, 0);
    } else if (document.querySelector("#skills-div").getBoundingClientRect().top >= window.innerHeight) { // initial screens before camera movement

        camera.left = frustumSize * aspectRatio / -2;
        camera.right = frustumSize * aspectRatio / 2;
        camera.top = frustumSize + frustumHeight;
        camera.bottom = frustumHeight;
        
        // Update the camera's look-at direction (assuming 'object' is the object you want to focus on)
        camera.lookAt(0, 0, 0);
    }
    theCanvas.style.opacity = 1;

}

function updateCameraMobile() {
    // Calculate the scroll progress within the range
    let distanceFromTop = window.innerHeight - document.querySelector("#about-nav-anchor").getBoundingClientRect().top;

    let scrollProgress = 1 - (distanceFromTop / (window.innerHeight - window.innerHeight/4));
    
    scrollProgress = Math.min(1, Math.max(0, scrollProgress));
    
    scrollProgress = 1 - scrollProgress;    // Ensure scroll is within the specified range

    if (document.querySelector("#work-exs").getBoundingClientRect().top < window.innerHeight*0.9) { // during camera movement

        theCanvas.classList.add("artboard-fade-out-mobile");
        theCanvas.classList.remove("artboard-fade-in-mobile");

        if (typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function') {
            document.querySelector("#start-button-container").classList.remove("start-button-fade-in");
        }

        
    } else if (document.querySelector("#work-exs").getBoundingClientRect().top >= window.innerHeight*0.9) { // initial screens before camera movement

        theCanvas.classList.add("artboard-fade-in-mobile");
        theCanvas.classList.remove("artboard-fade-out-mobile");

        if (typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function') {
            document.querySelector("#start-button-container").classList.add("start-button-fade-in");
        }
    }
}

function updateCameraZoomMobile () {
    camera.left = frustumSize * aspectRatio / -2;
    camera.right = frustumSize * aspectRatio / 2;
    camera.top = frustumSize + frustumHeight;
    camera.bottom = frustumHeight;
}

if(isTouchDevice) updateCameraZoomMobile();

// intersectionHandler_________________________________________________________________________________________

const observerOptions2 = {
	root: null,
	threshold: [0.05], // add any new intersection ratio values here
	rootMargin: '0px'
};

// observer for purpleBG

let observerPurpleBg = new IntersectionObserver(intersectionHandlerPurpleBg, observerOptions2);

function intersectionHandlerPurpleBg(entries, observerPurpleBg) {
	entries.forEach((entry) => {
		intersectionConditionalPurpleBg(entry, projectPurpleBackground, 0.05)
	});
};

document.querySelectorAll("#project-container").forEach((row, index) => {
	observerPurpleBg.observe(row);
});

stopRender = false;

function intersectionConditionalPurpleBg(entry, element, threshold) {
    if (entry.isIntersecting && entry.intersectionRatio > threshold) {
        element.classList.add("purple-background-fadein");
        stopRender = true;
    } else {
        element.classList.remove("purple-background-fadein");
        stopRender = false;
    }
}

// observer for work ex for stopping render on mobile

let observerWorkExMobile = new IntersectionObserver(intersectionHandlerWorkExMobile, observerOptions2);

function intersectionHandlerWorkExMobile(entries, observerWorkExMobile) {
	entries.forEach((entry) => {
		intersectionConditionalWorkExMobile(entry, 0.05)
	});
};

observerWorkExMobile.observe(document.querySelector("#button-for-mobile"));

function intersectionConditionalWorkExMobile(entry, threshold) {
    if (entry.isIntersecting && entry.intersectionRatio > threshold && isTouchDevice) {
        stopRender = true;
    } else {
        stopRender = false;
    }
}



function moveCameraOnScroll() {

    if(isScrolling && !isTouchDevice) {
        updateCamera()
    } else if(isScrolling && isTouchDevice) {
        updateCameraMobile()
    }
        
    // Update the projection matrix
    camera.updateProjectionMatrix();
    
    isScrolling = false; // Reset scrolling flag after rendering
}


function scrollHandler() {
    if (!isScrolling) {
        let scrollY = window.scrollY;
        if (scrollY < window.innerHeight * 5.5 && currentTime > loadDimEndTime) {
            isScrolling = true;
            requestAnimationFrame(moveCameraOnScroll);
        }
    }
}

window.addEventListener("scroll", scrollHandler);

function degToRad(degrees) {
    return degrees * (Math.PI / 180);
}

// window resize

function onWindowResize() {

    w = window.innerWidth;
    h = window.innerHeight;
    aspectRatio = w/h;

    if (('ontouchstart' in window || navigator.maxTouchPoints) && wConstant <= 600) {
        isTouchDevice = true;
    } else {
        isTouchDevice = false;
    }

    if(isTouchDevice) {
        h = hConstant;
        w = wConstant;
    }

    if(window.innerWidth < 600) {
        dpiDivide = 2;
    } else if (window.devicePixelRatio >= 2 && window.innerWidth >= 600) {
        dpiDivide = 1.5;
    } else {
        dpiDivide = 1;
    }

    if(window.innerWidth < 600) {
        frustumSizeMax = 45;
    } else {
        frustumSizeMax = 50;
    }
    
    m = (frustumSizeMax - frustumSizeMin) / (aspectRatio2 - aspectRatio1);
    b = frustumSizeMin - m * aspectRatio1;

    if(aspectRatio < 1.7) { // reduces height of scene beyond a certian aspect ratio
        frustumSize =  m * aspectRatio + b;
    } else {
        frustumSize = 29;
    }

    moveCameraOnScroll();

    if(!isTouchDevice) {
        updateCamera();
    } else {
        updateCameraMobile();
    }

    camera.updateProjectionMatrix();

    // dpi = window.devicePixelRatio
    renderer.setSize(w, h)

    renderer.setPixelRatio(dpiDivide)

    composer.setSize(w, h)
    
    composer.render(scene, camera)
}

function debounce(func, delay) {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(func, delay);
}

window.addEventListener('resize', () => {
  debounce(onWindowResize, 250); // Adjust the delay (in milliseconds) as needed
});

let size = renderer.getDrawingBufferSize( new THREE.Vector2() );
let renderTarget = new THREE.WebGLRenderTarget( Math.round(size.width/1.25), Math.round(size.height/1.25), { samples: 3, type: THREE.HalfFloatType } );

//const fxaaPass = new ShaderPass(FXAAShader)
const outputPass = new OutputPass();
const renderPass = new RenderPass(scene, camera)
const composer = new EffectComposer(renderer, renderTarget)

const bloomPass = new UnrealBloomPass(
    new THREE.Vector2(window.innerWidth/3, window,innerHeight/3),
    1.4, //strength, set to 0.6
    0.7, //radius
    1 //threshold
);

const params = {
    threshold: 1,
    strength: 1.6,
    radius: 0.7,
    exposure: 1 // can make it 0.6 for initial load darkness
};

bloomPass.threshold = params.threshold;
bloomPass.strength = params.strength;
bloomPass.radius = params.radius;

composer.addPass(renderPass)
composer.addPass(bloomPass)
composer.addPass(outputPass)

window.addEventListener( 'resize', onWindowResize );

// animate function

function animate() {
    // if(mixer) mixer.update(clock.getDelta());
    if(mixer) mixer.update(clock.getDelta());

    // controls.update();
    currentTime += 1/60;

    changeExposureOverTime(loadDimStartTime, loadDimEndTime);
    // eyeLoad()
    animateChin();
    updateCanvasOpacity()

    // Call the checkForInactivity function to start monitoring inactivity
    if(currentTime >= 8) {
        checkForInactivityChin(chin);
        checkForInactivityHair(GLTFHair);
    }

    // renderer.render(scene, camera)
    if(stopRender === false) {
        composer.render(scene, camera)
    }

    window.requestAnimationFrame(animate)
}

animate();